import React from 'react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBluetooth } from '../context/BluetoothContext';
import './Home.css';

const Home: FC = () => {
  const navigate = useNavigate();
  const { devices, addDevice, removeDevice, toggleDevice, updateDeviceMapping } = useBluetooth();

  const handleConnect = async () => {
    try {
      const device = await (navigator as any).bluetooth.requestDevice({
        optionalServices: [
          "4fafc201-1fb5-459e-8fcc-c5c9c331915d",
          "7b691dff-9062-4192-b46a-692e0da81d91",
        ],
        acceptAllDevices: true,
      });

      const server = await device.gatt.connect();
      
      const powerService = await server.getPrimaryService(
        "4fafc201-1fb5-459e-8fcc-c5c9c331915d"
      );
      const powerCharacteristic = await powerService.getCharacteristic(
        "beb5483e-36e1-4688-b7f5-ea07361b26ab"
      );

      const commandService = await server.getPrimaryService(
        "7b691dff-9062-4192-b46a-692e0da81d91"
      );
      const commandCharacteristic = await commandService.getCharacteristic(
        "1d3c6498-cfdf-44a1-9038-3e757dcc449d"
      );

      addDevice({
        id: device.id,
        name: device.name || `Device ${devices.length + 1}`,
        enabled: true,
        powerCharacteristic,
        commandCharacteristic,
        mapping: 1,
      });
    } catch (error) {
      console.error('Bluetooth connection failed:', error);
    }
  };

  return (
    <div className="home-container">
      <h1>Welcome to goodvibes!</h1>
      
      {devices.length > 0 && (
        <div className="devices-list">
          <h2>Connected Devices</h2>
          {devices.map(device => (
            <div key={device.id} className="device-card">
              <div className="device-info">
                <span className="device-name">{device.name}</span>
                <div className="device-controls">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={device.enabled}
                      onChange={() => toggleDevice(device.id)}
                    />
                    <span className="slider"></span>
                  </label>
                  <div className="mapping-control">
                    <span>Mapping: {device.mapping.toFixed(2)}</span>
                    <input
                      type="range"
                      min="-1"
                      max="1"
                      step="0.1"
                      value={device.mapping}
                      onChange={(e) => updateDeviceMapping(device.id, parseFloat(e.target.value))}
                      className="mapping-slider"
                    />
                  </div>
                  <button
                    className="remove-button"
                    onClick={() => removeDevice(device.id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <button className="connect-button" onClick={handleConnect}>
        {devices.length === 0 ? 'Connect a Device' : 'Add Another Device'}
      </button>
      
      {devices.length > 0 && (
        <button 
          className="start-button"
          onClick={() => navigate('/touch')}
        >
          Start Controller
        </button>
      )}
    </div>
  );
};

export default Home; 