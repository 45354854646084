import { useCallback, useRef } from 'react';

type SendFunction = (value: any) => Promise<void>;

export const useThrottledSend = (send: SendFunction, minInterval: number) => {
    const lastSendTime = useRef<number>(0);
    const pendingValue = useRef<any | null>(null);
    const sendPromise = useRef<Promise<void> | null>(null);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    const scheduleSend = useCallback(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        const now = Date.now();
        const timeSinceLastSend = now - lastSendTime.current;
        const delay = Math.max(0, minInterval - timeSinceLastSend);

        timeoutId.current = setTimeout(async () => {
            if (pendingValue.current === null) return;

            const valueToSend = pendingValue.current;
            pendingValue.current = null;

            try {
                await send(valueToSend);
                lastSendTime.current = Date.now();
            } catch (error) {
                // If send fails, try to send the latest value again
                if (pendingValue.current !== null) {
                    scheduleSend();
                }
            } finally {
                sendPromise.current = null;
            }
        }, delay);
    }, [send, minInterval]);

    const throttledSend = useCallback((value: any) => {
        pendingValue.current = value;

        if (sendPromise.current) {
            // If there's an ongoing send, just update the pending value
            return;
        }

        scheduleSend();
    }, [scheduleSend]);

    return throttledSend;
}; 