import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useBluetooth } from '../context/BluetoothContext';
import './Touch.css';
import { useThrottledSend } from '../hooks/useThrottledSend';
import { useWakeLock } from '../hooks/useWakeLock';

interface TouchPosition {
    x: number;
    y: number;
}

interface TrailPoint {
    x: number;
    y: number;
}

interface Particle {
    id: number;
    x: number;
    y: number;
    vx: number;  // velocity x
    vy: number;  // velocity y
    opacity: number;
    color: string;
    size: number;
}

const WAVE_POINTS = 200; // Increased number of points for better sampling
const POINT_SPACING = 0.5; // Decreased spacing for denser point sampling
const TRAIL_LENGTH = 120; // Increased trail length for longer history
const MAX_PARTICLES = 50;
const PARTICLE_COLORS = [
    '#ff1493', // Deep pink
    '#ff69b4', // Hot pink
    '#da70d6', // Orchid
    '#9370db', // Medium purple
    '#8a2be2', // Blue violet
    '#ff0066', // Bright pink
    '#c71585', // Medium violet red
    '#9932cc'  // Dark orchid
];
const PARTICLE_LIFETIME = 0.99; // Opacity multiplier per frame
const SEND_INTERVAL = 200; // Send commands every 50ms
const MIN_Y_THRESHOLD = 90; // Bottom 10% threshold
const MAX_Y_THRESHOLD = 10; // Top 10% threshold

const Touch: FC = () => {
    const { devices, send } = useBluetooth();
    const [waveSpeed, setWaveSpeed] = useState(0.3);
    const [targetX, setTargetX] = useState(10);
    const [touchPos, setTouchPos] = useState<TouchPosition>({ x: 80, y: 50 });
    const [trail, setTrail] = useState<TrailPoint[]>(() => {
        // Initialize trail points from touch position to the left
        return Array.from({ length: WAVE_POINTS }, (_, i) => ({
            x: 80 - (i * (TRAIL_LENGTH / WAVE_POINTS)),
            y: 50,
        }));
    });
    const containerRef = useRef<HTMLDivElement>(null);
    const animationFrameRef = useRef<number | null>(null);
    const [isPressed, setIsPressed] = useState(false);
    const [particles, setParticles] = useState<Particle[]>([]);
    const particleIdCounter = useRef(0);
    const lastSentValue = useRef<number | null>(null);

    useWakeLock();

    const getContainerOffset = () => {
        if (!containerRef.current) return { x: 0, y: 0 };
        const rect = containerRef.current.getBoundingClientRect();
        const containerHeight = rect.height;
        const containerTop = rect.top;
        // Convert to percentage of viewport height
        return {
            x: 0,
            y: (containerTop / window.innerHeight) * 100,
            scale: (containerHeight / window.innerHeight)
        };
    };

    const createParticles = (y: number) => {
        // Calculate spawn probability based on y position
        const spawnProbability = 0.3 * (100 - y) / 100;

        if (Math.random() > spawnProbability) {
            return [];
        }

        const offset = getContainerOffset();
        const angle = (Math.PI * 2) + (Math.random() - 0.5) * 0.5;
        const speed = 0.1 + Math.random() * 0.5;

        // Scale the y position to match the container's height and add the offset
        const adjustedY = (y * (offset.scale ?? 1)) + offset.y;

        return [{
            id: particleIdCounter.current++,
            x: targetX,
            y: adjustedY,
            vx: -Math.cos(angle) * speed,
            vy: -Math.sin(angle) * speed * 10,
            opacity: 1,
            color: PARTICLE_COLORS[Math.floor(Math.random() * PARTICLE_COLORS.length)],
            size: 0.3 + Math.random() * 0.3
        }];
    };

    const mapYToValue = (y: number) => {
        if (y >= MIN_Y_THRESHOLD) {
            return 0; // Bottom 10%
        }
        if (y <= MAX_Y_THRESHOLD) {
            return 100; // Top 10%
        }
        // Map y from [MAX_Y_THRESHOLD, MIN_Y_THRESHOLD] to [100, 0]
        return Math.round(100 - ((y - MAX_Y_THRESHOLD) * 100 / (MIN_Y_THRESHOLD - MAX_Y_THRESHOLD)));
    };

    const applyDeviceMapping = (value: number, mapping: number) => {
        // If mapping is positive, normal scaling
        // If mapping is negative, reverse scaling
        if (mapping < 0) {
            return Math.round((100 - value) * (-mapping));
        } else {
            return Math.round(value * mapping);
        }
    };

    const sendToDevices = useCallback(async (baseValue: number) => {
        // Only send if the base value has changed
        if (baseValue === lastSentValue.current) return;

        // Send to each device with its mapping applied
        await Promise.all(devices.map(device => {
            if (device.enabled) {
                const mappedValue = applyDeviceMapping(baseValue, device.mapping);
                return send({ msg: `set-lamp on ${mappedValue}`, deviceId: device.id });
            }
        }));

        lastSentValue.current = baseValue;
    }, [devices, send]);

    const throttledSend = useThrottledSend(sendToDevices, SEND_INTERVAL);

    const sendCommandsForIntersections = useCallback((intersections: number[]) => {
        const y = intersections.length > 0 ? Math.max(...intersections) : null;

        if (y !== null) {
            const baseValue = mapYToValue(y);
            throttledSend(baseValue);
        }
    }, [throttledSend]);

    const updateParticles = (trail: TrailPoint[]) => {
        // Find intersection points with vertical line
        const intersections = trail.reduce((acc, point, index) => {
            if (index === 0) return acc;
            const prevPoint = trail[index - 1];

            if ((prevPoint.x >= targetX && point.x <= targetX) ||
                (prevPoint.x <= targetX && point.x >= targetX)) {
                const t = (targetX - point.x) / (prevPoint.x - point.x);
                const y = point.y + t * (prevPoint.y - point.y);
                acc.push(y);
            }
            return acc;
        }, [] as number[]);

        // Send commands based on intersections
        sendCommandsForIntersections(intersections);

        // Create new particles at intersection points
        const newParticles = intersections.flatMap(y => createParticles(y));

        // Update existing particles with individual movement
        setParticles(prevParticles => {
            const updatedParticles = prevParticles
                .map(particle => ({
                    ...particle,
                    x: particle.x + particle.vx,
                    y: particle.y + particle.vy,
                    vx: particle.vx * 0.98, // Add air resistance
                    vy: particle.vy * 0.98 + 0.01, // Add gravity and air resistance
                    opacity: particle.opacity * PARTICLE_LIFETIME
                }))
                .filter(particle =>
                    particle.opacity > 0.01
                );

            return [...updatedParticles, ...newParticles].slice(-MAX_PARTICLES);
        });
    };

    const updateWave = () => {
        setTrail(prevTrail => {
            const newTrail = [...prevTrail];

            // If moving right, add new points
            if (touchPos.x > newTrail[0].x) {
                const pointsToAdd = Math.ceil((touchPos.x - newTrail[0].x) / POINT_SPACING);
                for (let i = 0; i < pointsToAdd; i++) {
                    newTrail.unshift({ x: touchPos.x, y: touchPos.y });
                }
            } else {
                // If moving left or staying still, update all points between cursor and last point
                const lastUpdatedIndex = newTrail.findIndex(point => point.x < touchPos.x);
                for (let i = 0; i < lastUpdatedIndex; i++) {
                    newTrail[i] = { x: touchPos.x, y: touchPos.y };
                }
            }

            // Move all points left at constant speed
            for (let i = 0; i < newTrail.length; i++) {
                newTrail[i] = {
                    x: newTrail[i].x - waveSpeed,
                    y: newTrail[i].y
                };
            }

            // Remove points that have moved far off screen
            while (newTrail.length > WAVE_POINTS && newTrail[newTrail.length - 1].x < -20) {
                newTrail.pop();
            }
            updateParticles(newTrail);

            return newTrail;
        });

        animationFrameRef.current = requestAnimationFrame(updateWave);
    };

    const handleTouchStart = (event: TouchEvent | MouseEvent) => {
        setIsPressed(true);
        handleTouch(event);
    };

    const handleTouchEnd = () => {
        setIsPressed(false);
    };

    const handleTouch = useCallback((event: TouchEvent | MouseEvent) => {
        if (!containerRef.current) return;

        event.preventDefault();

        const rect = containerRef.current.getBoundingClientRect();
        let x: number;
        let y: number;

        if ('touches' in event) {
            x = event.touches[0].clientX - rect.left;
            y = event.touches[0].clientY - rect.top;
        } else {
            x = event.clientX - rect.left;
            y = event.clientY - rect.top;
        }

        // Normalize coordinates as percentages
        let normalizedX = (x / rect.width) * 100;
        const normalizedY = (y / rect.height) * 100;

        // If touch is to the left of the target line, set X to the target line position plus a small offset
        const onePixelAsPercentage = (20 / rect.width) * 100;
        normalizedX = Math.max(normalizedX, targetX + onePixelAsPercentage);

        setTouchPos({ x: normalizedX, y: normalizedY });
    }, [targetX]);

    const createWavePath = () => {
        if (!trail.length) return '';

        // Create a smooth curve through the trail points
        const points = trail.map((point, index) => {
            if (index === 0) {
                return `M ${point.x} ${point.y}`;
            }
            // Use cubic curves for smoother lines
            const prevPoint = trail[index - 1];
            const cpX1 = prevPoint.x + (point.x - prevPoint.x) * 0.5;
            const cpX2 = point.x - (point.x - prevPoint.x) * 0.5;
            return `C ${cpX1} ${prevPoint.y} ${cpX2} ${point.y} ${point.x} ${point.y}`;
        }).join(' ');

        return points;
    };

    useEffect(() => {
        // Start the animation
        animationFrameRef.current = requestAnimationFrame(updateWave);

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [touchPos]);

    useEffect(() => {
        const element = containerRef.current;
        if (!element) return;

        // Mouse events
        element.addEventListener('mousedown', handleTouchStart);
        element.addEventListener('mousemove', handleTouch);
        element.addEventListener('mouseup', handleTouchEnd);
        element.addEventListener('mouseleave', handleTouchEnd);

        // Touch events
        element.addEventListener('touchstart', handleTouchStart);
        element.addEventListener('touchmove', handleTouch);
        element.addEventListener('touchend', handleTouchEnd);
        element.addEventListener('touchcancel', handleTouchEnd);

        return () => {
            element.removeEventListener('mousedown', handleTouchStart);
            element.removeEventListener('mousemove', handleTouch);
            element.removeEventListener('mouseup', handleTouchEnd);
            element.removeEventListener('mouseleave', handleTouchEnd);
            element.removeEventListener('touchstart', handleTouchStart);
            element.removeEventListener('touchmove', handleTouch);
            element.removeEventListener('touchend', handleTouchEnd);
            element.removeEventListener('touchcancel', handleTouchEnd);
        };
    }, [handleTouch]);

    return (
        <div className="touch-page">
            {/* Particles SVG moved to top level and made full screen */}
            <svg className="particles-svg" preserveAspectRatio="none" viewBox="0 0 100 100">
                {particles.map(particle => (
                    <circle
                        key={particle.id}
                        cx={particle.x}
                        cy={particle.y}
                        r={particle.size}
                        fill={particle.color}
                        style={{
                            filter: 'blur(0.01px) brightness(1.5)',
                            stroke: particle.color,
                            strokeWidth: '0.2',
                        }}
                        className="particle"
                    />
                ))}
            </svg>

            <header className="touch-header">
                <h1>Touch Controller</h1>
                <div className="control-sliders">
                    <div className="slider-container">
                        <label htmlFor="wave-speed">Wave Speed: {waveSpeed.toFixed(2)}</label>
                        <input
                            id="wave-speed"
                            type="range"
                            min="0.1"
                            max="1.0"
                            step="0.1"
                            value={waveSpeed}
                            onChange={(e) => setWaveSpeed(Number(e.target.value))}
                        />
                    </div>
                    <div className="slider-container">
                        <label htmlFor="target-x">Target X: {targetX}%</label>
                        <input
                            id="target-x"
                            type="range"
                            min="5"
                            max="95"
                            step="1"
                            value={targetX}
                            onChange={(e) => setTargetX(Number(e.target.value))}
                        />
                    </div>
                </div>
                <div className="devices-status">
                    {devices.map(device => (
                        <div key={device.id} className="device-status">
                            <span className={`status-dot ${!device.lastPollTime || device.lastPollTime > Date.now() - 5000 ? 'active' : ''}`} />
                            <span className="device-name">{device.name}</span>
                        </div>
                    ))}
                </div>
                <Link to="/" className="back-link">Back to Home</Link>
            </header>

            <div
                ref={containerRef}
                className="touch-container"
            >
                <svg className="trail-svg" preserveAspectRatio="none" viewBox="0 0 100 100">
                    {/* Add gradient definition */}
                    <defs>
                        <linearGradient id="targetAreaGradient" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor="rgba(255, 20, 147, 0.1)" />
                            <stop offset="100%" stopColor="rgba(147, 20, 255, 0.1)" />
                        </linearGradient>
                    </defs>

                    {/* Gradient rectangle */}
                    <rect
                        x="0"
                        y="0"
                        width={targetX}
                        height="100"
                        fill="url(#targetAreaGradient)"
                    />

                    {/* Vertical line with updated style */}
                    <line
                        x1={targetX}
                        y1="0"
                        x2={targetX}
                        y2="100"
                        className="target-line"
                        stroke="#ff1493"
                        strokeWidth="0.5"
                    />

                    {/* Trail path */}
                    <path
                        d={createWavePath()}
                        className="trail-path"
                        vectorEffect="non-scaling-stroke"
                    />
                </svg>

                <div
                    className="touch-indicator"
                    style={{
                        left: `${touchPos.x}%`,
                        top: `${touchPos.y}%`
                    }}
                />
            </div>
        </div>
    );
};

export default Touch;