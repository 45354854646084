import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { BluetoothProvider } from './context/BluetoothContext';
import Home from './pages/Home';
import Touch from './pages/Touch';
import React from 'react';

function App() {
    return (
        <BluetoothProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/touch" element={<Touch />} />
                </Routes>
            </Router>
        </BluetoothProvider>
    );
}

export default App;