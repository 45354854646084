import { createContext, useContext, useState, FC, ReactNode, useRef, useEffect, useCallback } from 'react';
import { BluetoothDevice, BluetoothContextType } from '../types/bluetooth';
import React from 'react';

const BluetoothContext = createContext<BluetoothContextType | undefined>(undefined);

const POLL_INTERVAL = 2000; // Poll every second


export const BluetoothProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [devices, setDevices] = useState<BluetoothDevice[]>([]);
  const pollInterval = useRef<NodeJS.Timeout | null>(null);

  const send = useCallback(async (message: { msg: string, deviceId: string }) => {
    // Send message to all enabled devices
    const enabledDevices = devices.filter(device => device.enabled);

    const device = enabledDevices.find(device => device.id === message.deviceId);
    if (!device) {
      console.error(`Device with ID ${message.deviceId} not found`);
      return;
    }

    try {
      const encoder = new TextEncoder();
      const data = encoder.encode(message.msg);
      await device.commandCharacteristic.writeValue(data);
      console.log(`Sent message to device ${device.id}: ${message}`);
    } catch (error) {
      console.error(`Failed to send message to device ${device.id}:`, error);
    }
  }, [devices]);

  const pollDevice = useCallback(async (device: BluetoothDevice) => {
    try {
      await send({ msg: 'get-lamp', deviceId: device.id });
      // Update the device's lastPollTime on successful poll
      setDevices(prevDevices => prevDevices.map(d =>
        d.id === device.id
          ? { ...d, lastPollTime: Date.now() }
          : d
      ));
    } catch (error) {
      console.error(`Error polling device ${device.name}:`, error);
    }
  }, [send]);

  useEffect(() => {
    // Start polling when there are enabled devices
    if (devices.some(device => device.enabled)) {
      pollInterval.current = setInterval(() => {
        devices.forEach(device => {
          if (device.enabled) {
            pollDevice(device);
          }
        });
      }, POLL_INTERVAL);
    }

    // Cleanup polling when no devices are enabled
    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
        pollInterval.current = null;
      }
    };
  }, [devices, pollDevice]);

  const addDevice = (device: BluetoothDevice) => {
    setDevices(prev => {
      if (prev.some(d => d.id === device.id)) {
        return prev;
      }
      return [...prev, { ...device, enabled: true }];
    });
  };

  const removeDevice = (id: string) => {
    setDevices(prev => prev.filter(device => device.id !== id));
  };

  const toggleDevice = (id: string) => {
    setDevices(prev =>
      prev.map(device =>
        device.id === id
          ? { ...device, enabled: !device.enabled }
          : device
      )
    );
  };

  const updateDeviceMapping = (id: string, mapping: number) => {
    setDevices(prev =>
      prev.map(device =>
        device.id === id
          ? { ...device, mapping }
          : device
      )
    );
  };

  return (
    <BluetoothContext.Provider value={{
      devices,
      addDevice,
      removeDevice,
      toggleDevice,
      updateDeviceMapping,
      send
    }}>
      {children}
    </BluetoothContext.Provider>
  );
};

export const useBluetooth = () => {
  const context = useContext(BluetoothContext);
  if (context === undefined) {
    throw new Error('useBluetooth must be used within a BluetoothProvider');
  }
  return context;
}; 