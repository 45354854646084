import { useEffect, useRef } from 'react';

export const useWakeLock = () => {
    const wakeLockRef = useRef<any | null>(null);

    useEffect(() => {
        const requestWakeLock = async () => {
            try {
                // Only request wake lock if the API is supported
                if ('wakeLock' in navigator) {
                    wakeLockRef.current = await (navigator.wakeLock as any).request('screen');
                }
            } catch (err) {
                console.log('Wake Lock error:', err);
            }
        };

        // Request wake lock when component mounts
        requestWakeLock();

        // Re-request wake lock when visibility changes (e.g., user switches tabs and returns)
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                requestWakeLock();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup: release wake lock and remove event listener
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (wakeLockRef.current) {
                wakeLockRef.current.release().catch(console.error);
            }
        };
    }, []);
}; 